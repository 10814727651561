<template>
   <div class="birthday-home">
      <div class="date">
         <div>{{ date.day }}</div>
         <div>{{ date.month }}</div>
      </div>
      <div class="birthday-home-list">
         <div
            class="birthday-home-list-row"
            v-for="(x, n) in rowCount"
            :key="n"
         >
            <div class="user" v-for="(user, k) in itemCountInRow(x)" :key="k">
               <div class="img">
                  <img :src="handleUserImage(user.imagen_perfil)" alt="" />
               </div>
               <div class="info">
                  <div class="name">
                     {{ user.primer_nombre + " " + user.apellido_paterno }}
                  </div>
                  <div class="job">
                     {{ user.cargo[0].nombre_cargo }}
                  </div>
               </div>
            </div>
         </div>
         <div class="birthday-home-list no-results" v-if="no_birthdays">
            <span>No hay cumpleaños hoy.</span>
         </div>
         <div class="go-to-birthday">
            <button>Ir a cumpleaños</button>
         </div>
      </div>
   </div>
</template>

<script>
import { BIRTHDAYS_TODAY } from "@/apollo/queries";

export default {
   data() {
      return {
         user_default_img: require("@/assets/img/user_default_img.svg"),
         months: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
         ],
         date: {
            day: null,
            month: null,
         },
         no_birthdays: false,
         birthday_list: [],
         id_empresa: null,
      };
   },
   mounted() {
      this.id_empresa = this.$ls.get("user").empresa[0].id_empresa;
      this.handleDate();
      this.birthdaysToday();
   },
   computed: {
      rowCount() {
         return Math.ceil(this.birthday_list.length / 2);
      },
   },
   methods: {
      // ajusta los usuarios en rows de dos items
      itemCountInRow(index) {
         return this.birthday_list.slice((index - 1) * 2, index * 2);
      },
      // Obtener fecha de hoy
      handleDate() {
         var new_date = new Date();

         var day = new_date.getDate();
         var month = this.months[new_date.getMonth()];

         if (day < 10) {
            day = "0" + day.toString();
         }

         this.date.day = day;
         this.date.month = month;
      },
      // Cumpleaños de hoy
      birthdaysToday() {
         this.$apollo
            .mutate({
               mutation: BIRTHDAYS_TODAY,
               variables: {
                  id_empresa: this.id_empresa,
               },
            })
            .then((res) => {
               var resp = res.data.birthdaysToday;
               console.log("birthdaysToday", resp);
               if (resp.length > 0) {
                  this.no_birthdays = false;
                  resp.forEach((birth) => {
                     this.birthday_list.push(birth);
                  });
               } else {
                  this.no_birthdays = true;
               }
            })
            .catch((err) => {
               console.log(err);
            });
      },
      // se encarga de mostrar la imagen de usuario
      handleUserImage(img) {
         if (img !== "" && img !== null && img !== undefined && img !== "img") {
            return img;
         } else {
            return this.user_default_img;
         }
      },
   },
};
</script>