<template>
   <div class="birthday">
      <div class="birthday-title">
         <h1>Cumpleaños</h1>
      </div>
      <div class="birthday-container">
         <div class="birthday-container-left">
            <div class="calendar">
               <div class="arrows">
                  <button @click="move(-1, 'backward')">
                     <font-awesome-icon icon="angle-left"></font-awesome-icon>
                  </button>
                  <button @click="move(+1, 'forward')">
                     <font-awesome-icon icon="angle-right"></font-awesome-icon>
                  </button>
               </div>
               <v-calendar
                  ::available-dates="{ start: null, end: null }"
                  :attributes="attrs"
                  @dayclick="onDayClick"
                  ref="calendar"
               />
            </div>
         </div>
         <div class="birthday-container-right">
            <div class="birthday-list">
               <div class="date">
                  <div>{{ date.day }}</div>
                  <div>{{ date.month }}</div>
               </div>
               <div
                  class="birthday-list-container"
                  v-if="!no_birthdays"
                  :class="no_birthdays && 'no-birthdays'"
               >
                  <div class="user" v-for="(user, k) in birthday_list" :key="k">
                     <div class="img">
                        <img
                           :src="handleUserImage(user.imagen_perfil)"
                           alt=""
                        />
                     </div>
                     <div class="info">
                        <div class="name">
                           {{
                              user.primer_nombre + " " + user.apellido_paterno
                           }}
                        </div>
                        <div class="job">
                           {{ user.cargo[0].nombre_cargo }}
                        </div>
                     </div>
                  </div>
               </div>
               <div
                  class="birthday-list-container no-birthdays"
                  v-if="no_birthdays"
               >
                  <span>No hay cumpleaños</span>
               </div>
            </div>
         </div>
      </div>

      <!-- Componenete Cumpleaños Home -->
      <div class="home-birthday">
         <BirthdayHome />
      </div>
   </div>
</template>

<script>
import {
   BIRTHDAYS_TODAY,
   BIRTHDAYS_DAYS,
   BIRTHDAYS_MONTH,
   BIRTHDAYS_DATES,
} from "@/apollo/queries";
import BirthdayHome from "@/components/Birthday/Birthday.vue";

export default {
   components: { BirthdayHome },
   data() {
      return {
         max_date: new Date(),
         months: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
         ],
         months_num: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
         ],
         user_default_img: require("@/assets/img/user_default_img.svg"),
         id_empresa: null,
         attrs: [
            {
               highlight: "gray",
               dates: new Date(),
               fillMode: "light",
            },
         ],
         birthday_list: [],
         no_birthdays: false,
         date: {
            day: null,
            month: null,
         },
         selected_month: null,
      };
   },
   mounted() {
      this.id_empresa = this.$ls.get("user").empresa[0].id_empresa;

      setInterval(() => {
         this.checkIfUserIsLogged();
      }, 100);
      var date = new Date();
      var month = date.getMonth();
      this.handleDate(date);
      this.birthdaysDays(month);
      this.birthdaysToday();
   },
   watch: {
      selected_date: function () {
         console.log("selected_date", this.selected_date);
      },
   },
   methods: {
      // Funcion para seleccionar fecha
      onDayClick(date) {
         console.log(date);
         this.handleDate(date.date);
         var day =
            date.day < 10 ? "0" + date.day.toString() : date.day.toString();
         var month =
            date.month < 10
               ? "0" + date.month.toString()
               : date.month.toString();
         this.birthdaysDates(day, month);
      },
      // Funcion para avanzar o retroceder de mes en el calendario
      async move(num, to) {
         const calendar = this.$refs.calendar;
         // funcion de v-calendar para avanzar o retroceder
         calendar.move(num);

         if (to == "backward") {
            if (this.selected_month == 0) {
               this.selected_month = 12;
            } else {
               this.selected_month--;
            }
         } else if (to == "forward") {
            if (this.selected_month == 12) {
               this.selected_month = 0;
            } else {
               this.selected_month++;
            }
         }
         console.log("move", to, this.selected_month);

         this.birthdaysDays(this.selected_month - 1);
      },
      // se encarga de mostrar la imagen de usuario
      handleUserImage(img) {
         if (img !== "" && img !== null && img !== undefined && img !== "img") {
            return img;
         } else {
            return this.user_default_img;
         }
      },
      // Cumpleaños de hoy
      birthdaysToday() {
         this.$apollo
            .mutate({
               mutation: BIRTHDAYS_TODAY,
               variables: {
                  id_empresa: this.id_empresa,
               },
            })
            .then((res) => {
               var resp = res.data.birthdaysToday;
               console.log("birthdaysToday", resp);
               if (resp.length > 0) {
                  this.no_birthdays = false;
                  resp.forEach((birth) => {
                     this.birthday_list.push(birth);
                  });
               } else {
                  this.no_birthdays = true;
               }
            })
            .catch((err) => {
               console.log(err);
            });
      },
      // Trae dias segun mes donde hay cumpleaños
      birthdaysDays(month) {
         console.log("birthdaysDays 1", month);

         var mes = month + 1;
         if (mes < 10) {
            mes = "0" + mes.toString();
         }
         console.log("birthdaysDays 2", mes);
         this.$apollo
            .mutate({
               mutation: BIRTHDAYS_DAYS,
               variables: {
                  id_empresa: this.id_empresa,
                  month: mes,
               },
            })
            .then((res) => {
               var resp = res.data.birthdaysDays;
               console.log("birthdaysDays resp", resp);
               var date = new Date();
               var year = date.getFullYear();
               // console.log(year, month);
               this.attrs = [];

               if (resp.length > 0) {
                  // aqui se guardan los dias obtenidos para que
                  // aparezcan destacados en el calendario
                  resp.forEach((day) => {
                     var dia = Number(day.day);
                     var obj = {
                        highlight: "gray",
                        fillMode: "light",
                        dates: new Date(year, month, dia),
                     };
                     // console.log(obj);
                     this.attrs.push(obj);
                     // console.log(this.attrs);
                  });
               }
            })
            .catch((err) => {
               console.log(err);
            });
      },
      // Trae meses donde hay cumpleaños
      birthdaysMonth() {
         this.$apollo
            .mutate({
               mutation: BIRTHDAYS_MONTH,
               variables: {
                  id_empresa: this.id_empresa,
               },
            })
            .then((res) => {
               var resp = res.data.birthdaysMonth;
               console.log("birthdaysMonth", resp);
            })
            .catch((err) => {
               console.log(err);
            });
      },
      // Trae personas que estan de cumpleaños segun fecha ingresada
      birthdaysDates(dia, mes) {
         console.log(dia, mes);
         this.$apollo
            .mutate({
               mutation: BIRTHDAYS_DATES,
               variables: {
                  id_empresa: this.id_empresa,
                  month: mes,
                  day: dia,
               },
            })
            .then((res) => {
               var resp = res.data.birthdaysDates;
               console.log("birthdaysDates", resp);
               if (resp.length > 0) {
                  this.no_birthdays = false;
                  this.birthday_list = [];
                  resp.forEach((birth) => {
                     this.birthday_list.push(birth);
                  });
               } else {
                  this.no_birthdays = true;
               }
            })
            .catch((err) => {
               console.log(err);
            });
      },
      // funcion para mostrar fecha de hoy
      handleDate(date) {
         var new_date;
         if (date) {
            new_date = new Date(date);
         } else {
            new_date = new Date();
         }

         var day = new_date.getDate();
         var month = this.months[new_date.getMonth()];
         this.selected_month = this.months_num[new_date.getMonth()];
         console.log("handleDate", this.selected_month);

         if (day < 10) {
            day = "0" + day.toString();
         }

         this.date.day = day;
         this.date.month = month;
      },
   },
};
</script>